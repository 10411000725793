import { store } from '../reduxStore';
import { User } from '../interfaces/user';
import { CreditCard } from '../interfaces/creditCard';
import moment from 'moment-timezone';
import { Reservation } from '../interfaces/reservation'
import * as v3ApiService from './v3ApiService';
import { V3User } from '../interfaces/v3/user';

export class apiService {
  static fetchParking(): Promise<any> {
    let url = '/parking/areas';
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true
    );
  }

  static fetchParkingArray(): Promise<any> {
    let url = '/parking/groups';
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true,
      true
    );
  }

  static fetchCarArray(carId: number, lat?: number, lon?: number): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));
    let suffix = '?token=' + store.getState().login.dbToken;
    if (carId > 0) suffix = '?carId=' + carId + '&token=' + store.getState().login.dbToken;
    if (lat && lon) {
      suffix += `&lat=${lat}&lon=${lon}`
    }
    let url = '/car/search' + suffix;
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true,
      false
    );
  }

  static fetchJanitorCarArray(lat?: number, lon?: number): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));
    let suffix = '?token=' + store.getState().login.dbToken;
    if (lat && lon) {
      suffix += `&lat=${lat}&lon=${lon}`
    }

    let url = '/task/search' + suffix;
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true,
      false
    );
  }

  static fetchJanitorCarsShortCached(lat?: number, lon?: number): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));
    let suffix = '?token=' + store.getState().login.dbToken;
    if (lat && lon) {
      suffix += `&lat=${lat}&lon=${lon}`
    }

    let url = '/task/searchCache' + suffix;

    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true,
      false
    );
  }

  static fetchJanitorCarsShort(lat?: number, lon?: number): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));
    let suffix = '?token=' + store.getState().login.dbToken;
    if (lat && lon) {
      suffix += `&lat=${lat}&lon=${lon}`
    }

    let url = '/task/searchShort' + suffix;

    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true,
      false
    );
  }

  static fetchJanitorCarDetails(carId:number, lat?: number, lon?: number): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));
    let suffix = '?token=' + store.getState().login.dbToken;
    suffix += `&carId=${carId}`
    if (lat && lon) {
      suffix += `&lat=${lat}&lon=${lon}`
    }

    let url = '/task/search' + suffix;
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true,
      false
    );
  }

  static fetchCarTasks(vehicleId: number): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));
    let suffix = 'token=' + store.getState().login.dbToken;

    let url = `/task/get?vehicleId=${vehicleId}&${suffix}`;
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true,
      false
    );
  }

  static changeTaskDetails(taskId: number, vehicletasktemplateid?:number, date?:number): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));
    let suffix = '&token=' + store.getState().login.dbToken;
    let url = '/task/change?taskId=' + taskId;
    url += vehicletasktemplateid ? '&vehicletasktemplateid='+vehicletasktemplateid : '';
    url += date ? '&date='+date : '';    
    url += suffix;
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true,
      false
    );
  }

  static fetchTaskDetails(taskId: number): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));
    let suffix = '?token=' + store.getState().login.dbToken;
    let url = '/task/' + taskId + '/open' + suffix;
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true,
      false
    );
  }

  static setTaskPriority(logRow: number, priority: string): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));
    let suffix = '?token=' + store.getState().login.dbToken;
    let url = '/logrow/' + logRow + '/priority/' + priority + suffix;

    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true,
      false
    );
  }

  static setTaskGroup(logRow: number, group: string): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));
    let suffix = '?token=' + store.getState().login.dbToken;
    let url = '/logrow/' + logRow + '/group/' + group + suffix;

    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true,
      false
    );
  }

  static saveFile(taskId: number, fileContents: string, filename: string): Promise<any> {
    let url = `/task/${taskId}/files`;
    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: store.getState().login.dbToken,
          filename,
          base64string: fileContents
        })
      },
      false)
  }

  static setVehicleOdometer(carId: number, drivenDistance: number): Promise<any> {
    let url = `/car/${carId}/updatedrivendistance`;
    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          drivenDistance,
          token: store.getState().login.dbToken
        })
      },
      true
    );
  }

  static setTaskComment(taskId: number, comment: string): Promise<any> {
    let url = '/task/comment';
    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          taskId: taskId.toString(),
          comment,
          token: store.getState().login.dbToken
        })
      },
      true
    );
  }

  static setTaskComplete(taskId: number): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));
    let suffix = '?token=' + store.getState().login.dbToken;
    let url = '/task/' + taskId + '/complete' + suffix;
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true,
      false
    );
  }

  static fetchJanitorRolesArray(): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));
    let suffix = '?token=' + store.getState().login.dbToken;
    let url = '/task/role' + suffix;
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true,
      false
    );
  }

  static fetchTaskTemplates(): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));
    let suffix = '?token=' + store.getState().login.dbToken;
    let url = '/task/templates' + suffix;
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true,
      false
    );
  }

  static insertTask(taskTemplateId: number, vehicleId: number): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));
    let suffix = 'token=' + store.getState().login.dbToken;
    let url = `/task/insert?vehicleId=${vehicleId}&taskTemplateId=${taskTemplateId}&${suffix}`;
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true,
      false
    );
  }

  static setTaskDescription(taskId: number, description: string): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));
    let suffix = 'token=' + store.getState().login.dbToken;
    let url = `/task/description?taskId=${taskId}&description=${description}&${suffix}`;
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true,
      false
    );
  }

  static fetchUser(): Promise<any> {
    let url = '/customer/profile';
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true,
      true
    );
  }

  static fetchUserPhoto(): Promise<any> {
    let url = '/customer/profile/photo';
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      false,
      true
    );
  }

  static fetchLastCustomerPhotos(carId: number): Promise<any> {
    let url = `/car/${carId}/lastcustomerphotos`;
    let suffix = '?token=' + store.getState().login.dbToken;
    url += suffix;
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true
    );
  }

  static fetchCityLocations(): Promise<any> {
    let url = '/city/locations';
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true
    );
  }

  static fetchTOS(): Promise<any> {
    let url = '/static/terms-of-service';
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true
    );
  }

  static fetchReservation(resNo: number = 0): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));

    let suffix = '?token=' + store.getState().login.dbToken + '&reservation=' + resNo;
    if (store.getState().map?.janitorAllowed && store.getState().map?.janitorMode === 'janitor') {
      suffix += '&janitorMode=true'; // must be 'true', not True/1 (BE will not read those)
    }

    let url = '/reservation/search' + suffix;
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true
    );
  }

  static fetchReservations(): Promise<Reservation[]> {
    // return v3ApiService.user.fetchReservations();
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));

    let suffix = '?token=' + store.getState().login.dbToken;

    let url = '/reservations' + suffix;
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true
    ) as Promise<Reservation[]>
  }

  static fetchTaskHistory(): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));

    let suffix = '?token=' + store.getState().login.dbToken;

    let url = '/task/history' + suffix;
    return fetchCustom(
        url,
        {
          method: 'GET'
        },
        true
    )
  }

  static fetchReservationsHistory(): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));

    let suffix = '?token=' + store.getState().login.dbToken;
    if (store.getState().map?.janitorAllowed && store.getState().map?.janitorMode === 'janitor') {
      suffix += '&janitorMode=true'; // must be 'true', not True/1 (BE will not read those)
    }

    let url = '/endedReservations' + suffix;
    return fetchCustom(
        url,
        {
          method: 'GET'
        },
        true
    )
  }

  static fetchNotPickedUpRes(): Promise<any> {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));

    let suffix = '?token=' + store.getState().login.dbToken;

    let url = '/maintenanceReservations' + suffix;
    return fetchCustom(
        url,
        {
          method: 'GET'
        },
        true
    )
  }

  static fetchV3User(): Promise<any> {
    return v3ApiService.user.fetchV3User();
  }

  static saveV3User(v3partialuser: any): Promise<any> {
    return v3ApiService.user.saveV3User(v3partialuser);

  }


  static fetchQualification(): Promise<any> {
    return v3ApiService.user.fetchQualification();
  }

  static fetchLogin(phoneNumber: string): Promise<any> {
    return v3ApiService.login.fetchLogin(phoneNumber);
    let url = '/device/login-customer';
    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          number: phoneNumber,
          key: 'reactLoginKey'
        })
      },
      true
    );
  }

  static fetchTwoFactor(phoneNumber: string, pin: string): Promise<any> {
    return v3ApiService.login.fetchTwoFactor(pin, phoneNumber);
    let url = '/device/login-two-factor';
    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          number: phoneNumber,
          pin,
          key: 'reactLoginKey'
        })
      },
      true
    );
  }

  static tryAuthHash(authenticationHash: string, customerId: number): Promise<any> {
    return v3ApiService.login.tryAuthHash(authenticationHash, customerId);
  }

  static getCitiesWithVehicles(): Promise<any> {
    return v3ApiService.vehicle.getCitiesWithVehicles();
  }

  static fetchParkingInstructions(cityName: string) {
    let url = `/city/${cityName}/parking-instructions`;
    return fetchCustom(url, {}, true);
  }

  static fetchMayEnd(id: number): Promise<any> {
    let url = '/reservation/' + id + '/may-end';
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true,
      true
    );
  }

  static claimReservation(id: number): Promise<any> {
    let url = `/reservation/${id}/claim`;
    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: store.getState().login.dbToken
        })
      },
      true
    );
  }

  static reservationStart(id: number): Promise<any> {
    let url = `/reservation/${id}/start `;
    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: store.getState().login.dbToken
        })
      },
      true
    );
  }

  static reservationEnd(id: number): Promise<any> {
    let url = `/reservation/${id}/end `;
    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: store.getState().login.dbToken
        })
      },
      true
    );
  }
  static createReservation(id: number, user: User, startTime?: string, durationMinutes?: number): Promise<any> {
    let url = '/reservation/create';

    if (startTime) {
      url = '/reservation/createmaintenance';
      moment.tz.setDefault('Europe/Helsinki')
      const startTimestamp = moment(startTime).unix();
      const endTimestamp = moment(startTime).add(durationMinutes, 'minutes').unix();
      const suffix = `?token=${store.getState().login.dbToken}&vehicleId=${id}&startTimestamp=${startTimestamp}&endTimestamp=${endTimestamp}`
      return fetchCustom(
        url + suffix,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        },
        true,
        false
      )
    }

    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          carId: id,
          phoneNumber: user.phone,
          email: user.email,
          agree: user.hasAcceptedTOS ? 1 : 0,
          quick: 1,
          react: 1,
          token: store.getState().login.dbToken
        })
      },
      true
    );
  }

  static extendReservation(id: number, endTime: string): Promise<any> {
    let url = `/reservation/${id}/extend`;

    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          datetime: endTime,
          token: store.getState().login.dbToken
        })
      },
      true
    );
  }

  static sendFeedback(id: number, feedback: string): Promise<any> {
    let url = '/reservation/feedback';
    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          reservationId: id,
          feedback,
          token: store.getState().login.dbToken
        })
      },
      true
    );
  }

  static sendUnflaggedFeedback(id: number, feedback: string, development: boolean): Promise<any> {
    let url = '/reservation/feedback-from-service';
    let dataObj = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        reservationId: id,
        key: development ? 'gonowkey' : '&2vDwkmA62ycs^0L@F^qW23a1vNT1XZf^WU9Pc#s',
        feedback,
        flag: 0,
        token: store.getState().login.dbToken
      })
    };
    // console.log("Sending: ", dataObj);
    return fetchCustom(url, dataObj, true);
  }

  static cancelReservation(id: number): Promise<any> {
    let url = '/reservation/' + id + '/cancel';
    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: store.getState().login.dbToken
        })
      },
      true
    );
  }

  static getInspectionSheet(id: number): Promise<any> {
    // return new Promise(res => res('https://backend.24rent.fi/24ADMIN/damages/damagetemplate.php?v=665\u0026reservation_id=211655'))
    let url = '/reservation/' + id + '/inspection-list';
    /*
    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: store.getState().login.dbToken
        })
      },
      true
    );
    */
    return fetchCustom(
      url,
      {
        method: 'GET',
      },
      true,
      true
    );
  }

  static openCloseDoors(id: number, suffix: string): Promise<any> {
    let url = '/reservation/' + id + suffix;
    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: store.getState().login.dbToken
        })
      },
      true
    );
  }

  static saveLanguage(language: string): Promise<any> {
    let url = '/customer/profile';
    return fetchCustom(
      url,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: store.getState().login.dbToken,
          language
        })
      },
      true
    );
  }

  static saveImage(url: string, method: string, photo: string, exifData?: any[]): Promise<any> {
    return fetchCustom(url, {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: store.getState().login.dbToken,
        photo,
        exifData
      })
    });
  }

  static saveUser(user: User): Promise<any> {
    let url = '/customer/profile';

    return fetchCustom(
      url,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: store.getState().login.dbToken,
          ...user
        })
      },
      true
    );
  }

  static reserveParking(id: number): Promise<any> {
    let url = '/parking/spots/' + id + '/vehicles';

    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: store.getState().login.dbToken
        })
      },
      true
    );
  }

  // Stripe-specific setup
  static setupCreditCard(): Promise<any> {
    let url = '/customer/setupcreditcardintent';

    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: store.getState().login.dbToken
        })
      },
      true
    );
  }

  // Stripe-specific saving
  static saveCreditCardToCustomer(intent: any): Promise<any> {
    let url = '/customer/savecreditcardtocustomer';
    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: store.getState().login.dbToken,
          paymentmethod: intent
        })
      },
      true
    );
  }

  // Stripe-specific payment reservation
  static reserveCreditCardPayment(reservationId: number): Promise<any> {
    let url = '/reservation/reservepayment';
    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: store.getState().login.dbToken,
          reservationId
        })
      },
      true
    );
  }

  // Stripe-specific payment execution
  static makeCreditCardPayment(reservationId: number): Promise<any> {
    let url = '/reservation/makepayment';
    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: store.getState().login.dbToken,
          reservationId
        })
      },
      true
    );
  }

  static saveCreditCard(creditCard: CreditCard): Promise<any> {
    let url = '/customer/payments';

    let creditCardFinal = {
      number: creditCard.credit1 + creditCard.credit2 + creditCard.credit3 + creditCard.credit4,
      cvc: creditCard.cvc,
      expiryMonth: creditCard.expiryMonth,
      expiryYear: creditCard.expiryYear
    };

    // console.log("CreditCardFinal: ", creditCardFinal);
    return fetchCustom(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          creditCard: creditCardFinal,
          amount: 1,
          token: store.getState().login.dbToken
        })
      },
      true
    );
  }

  static cancelParking(): Promise<any> {
    let url = '/parking/spots/vehicles';

    return fetchCustom(
      url,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      true,
      true
    );
  }

  static useVoucher(voucher: string): Promise<any> {
    let url = '/customer/profile';
    return fetchCustom(
      url,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: store.getState().login.dbToken,
          voucher
        })
      },
      true
    );
  }

  static useCoupon(voucher: string): Promise<any> {
    let url = '/customer/voucher';
    return fetchCustom(
      url,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: store.getState().login.dbToken,
          voucher
        })
      },
      true
    );
  }
  
  static getRealtimeCarPositions(carId:number): Promise<any> {
    let url = `/car/${carId}/telemetry`;
    let suffix = '?token=' + store.getState().login.dbToken;
    url+=suffix;
    return fetchCustom(
      url,
      {
        method: 'GET'
      },
      true
    );
  }
}

{
  let count=0;
  let time=new Date().getTime();
  //rate limit requests to 50 per second
  var rateLimit = () => {
    if (count++ > 50) { 
      console.log('Too many api requests, ignoring request.');
      return true;
    }
    if((new Date().getTime()-time )>1000) {
      count = 0;
      time = new Date().getTime();
    }
    return false;
  }
}

const fetchCustom = (url: string, options: any, isJson: boolean = false, token = false) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    if (rateLimit()) return new Promise((resolve, reject) => reject(new Error('Too many rest api requests, ignoring request.')));  //prevent too many requests during development (from accidental infinite loops for example)
  let apiUrl = store.getState().app.API_URL;

  if (store.getState().app.mockAPI) {
    alert('Mock API needs to be applied!');
  }

  let tokenStr = '';
  if (token) {
    if (store.getState().login.dbToken === '') return new Promise((resolve, reject) => reject(new Error('No token')));
    tokenStr = '?token=' + store.getState().login.dbToken;
  }
  let fullUrl = apiUrl + url + tokenStr;
  // console.log("Fetching from: ", fullUrl);

  options.cache = 'reload';
  options.headers = {
    ...options.headers,
    Authorization: "Bearer " + store.getState().login.jwt
  }

  return fetch(fullUrl, options).then(res => {
    let fetchError = statusCheck(res);
    if (fetchError) throw new Error(fetchError);
    if (isJson) {
      return res.json().then(res => errorCheck(res));
    }
    return res.blob().then(res => errorCheck(res));
  }).catch(err => console.log('Fetch error: ', err));
};

function statusCheck(res: any) {
  if (res.status !== 200) {
    // alert("Non 200 Response: " + res);
    return res.status;
    // return new Promise((resolve, reject) => reject(new Error('Expected 200 response, but received ' + res.status)));
  }
  return undefined;
}

function errorCheck(res: any) {
  if (res.error && res.errorCode && (res.errorCode === 1002 || res.errorCode === 1005)) {
    // if in anonymous/customer service mode, don't reject the 'anonymous' token
    if (store.getState().login.dbToken !== 'anonymous') {
      localStorage.removeItem('GoNowToken');
      localStorage.removeItem('GoNowStore');
      store.dispatch({ type: 'STORE_AUTH', payload: {token: '', jwt: ''} });
    }
    return new Promise((resolve, reject) => reject(new Error(res.errorCode)));
  } else if (res.ok !== undefined && (res.ok === false || res.ok === 0)) {
    return new Promise((resolve, reject) => reject(new Error(res.error)));
  }
  return res;
}

export default apiService;
